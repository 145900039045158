import React, { useState, useEffect } from 'react';
import { ThemeProvider, createGlobalStyle, keyframes } from 'styled-components';
import { Button, TextInput, Window, WindowHeader, WindowContent, ProgressBar } from 'react95';
import originalTheme from 'react95/dist/themes/original';
import styled from 'styled-components';
import snakeIcon from './assets/trump-icon.png';
import chatgptIcon from './assets/chatgpt.png';
import xIcon from './assets/twitter.png';
import telegramIcon from './assets/telegram.png';
import snakeHD from './assets/trump.gif';

const GlobalStyle = createGlobalStyle`
  body, html {
    font-family: 'MS Sans Serif', Arial, Tahoma, sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.desktopBackground};
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => theme.desktopBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  overflow: hidden;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
`;

const AppIcon = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  width: 20px;
  height: 20px;
  line-height: 10px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function App() {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isChatWindowVisible, setIsChatWindowVisible] = useState(false);
  const [isSnakeWindowVisible, setIsSnakeWindowVisible] = useState(false);
  const [chatInput, setChatInput] = useState('');
  const [chatLog, setChatLog] = useState([]);
  const [apiKey] = useState('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');
  const placeholderText = "D8srTN9BK1U6nSybiZmy3U6x9hmh5LM9vs7MWDeGpump";

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return prev + 10;
      });
    }, 300);
    return () => clearInterval(interval);
  }, []);

  const handleOpenSnakeWindow = () => {
    setIsSnakeWindowVisible(true);
    setIsChatWindowVisible(false);
  };

  const handleOpenChatWindow = () => {
    setIsChatWindowVisible(true);
    setIsSnakeWindowVisible(false);
  };

  const handleSendMessage = async () => {
    if (chatInput.trim() !== '') {
      const userMessage = { user: 'You', message: chatInput };
      setChatLog((prevLog) => [...prevLog, userMessage]);
      setChatInput('');

      try {
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [{ role: "user", content: chatInput }]
          })
        });

        const data = await response.json();
        const botMessage = { user: 'ChatGPT', message: data.choices[0].message.content };
        setChatLog((prevLog) => [...prevLog, botMessage]);
      } catch (error) {
        console.error("Error fetching ChatGPT response:", error);
        setChatLog((prevLog) => [...prevLog, { user: 'ChatGPT', message: 'Error: Unable to fetch response.' }]);
      }
    }
  };

  return (
    <ThemeProvider theme={originalTheme}>
      <GlobalStyle />
      <Wrapper>
        {loading ? (
          <Window style={{ width: 500, textAlign: 'center' }}>
            <WindowHeader>
              <span>Loading...</span>
            </WindowHeader>
            <WindowContent>
              <ProgressBar value={progress} />
            </WindowContent>
          </Window>
        ) : (
          <>
            <IconContainer>
              <div style={{ textAlign: 'center' }}>
                <AppIcon
                  src={snakeIcon}
                  alt="Open Trump95 Window"
                  onClick={handleOpenSnakeWindow}
                  title="Open Trump95 Window"
                />
                <div style={{ fontSize: '12px', marginTop: '4px', color: 'white' }}>Trump 95</div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <AppIcon
                  src={chatgptIcon}
                  alt="Open ChatGPT Window"
                  onClick={handleOpenChatWindow}
                  title="Open ChatGPT Window"
                />
                <div style={{ fontSize: '12px', marginTop: '4px', color: 'white' }}>ChatGPT</div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <AppIcon
                  src={xIcon}
                  alt="Open X"
                  onClick={() => window.open('https://x.com/trumpwin95', '_blank')}
                  title="Open X"
                />
                <div style={{ fontSize: '12px', marginTop: '4px', color: 'white' }}>X</div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <AppIcon
                  src={telegramIcon}
                  alt="Open Telegram"
                  onClick={() => window.open('https://t.me/trumpwin95sol', '_blank')}
                  title="Open Telegram"
                />
                <div style={{ fontSize: '12px', marginTop: '4px', color: 'white' }}>Telegram</div>
              </div>
            </IconContainer>

            {isSnakeWindowVisible && (
              <Window style={{ width: 500, marginTop: '20px' }}>
                <WindowHeaderStyled>
                  <span>Trump 95</span>
                  <CloseButton onClick={() => setIsSnakeWindowVisible(false)}>X</CloseButton>
                </WindowHeaderStyled>
                <WindowContent>
                  <img src={snakeHD} alt="Trump" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </WindowContent>
              </Window>
            )}

            {isChatWindowVisible && (
              <Window style={{ width: 600, marginTop: '20px' }}>
                <WindowHeaderStyled>
                  <span>ChatGPT</span>
                  <CloseButton onClick={() => setIsChatWindowVisible(false)}>X</CloseButton>
                </WindowHeaderStyled>
                <WindowContent>
                  <div style={{ maxHeight: '250px', overflowY: 'auto', marginBottom: '10px' }}>
                    {chatLog.map((entry, index) => (
                      <p key={index}><strong>{entry.user}:</strong> {entry.message}</p>
                    ))}
                  </div>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <TextInput
                      value={chatInput}
                      onChange={(e) => setChatInput(e.target.value)}
                      placeholder="Type your message..."
                      onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                      fullWidth
                    />
                    <Button onClick={handleSendMessage}>Send</Button>
                  </div>
                </WindowContent>
              </Window>
            )}

            <Window style={{ width: 600, marginTop: '20px' }}>
              <WindowHeaderStyled>
                <span>Contract Address</span>
              </WindowHeaderStyled>
              <WindowContent>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <TextInput
                    value={placeholderText}
                    readOnly
                    fullWidth
                    style={{ fontFamily: 'MS Sans Serif', color: 'black' }}
                  />
                  <Button onClick={() => navigator.clipboard.writeText(placeholderText)}>
                    Copy
                  </Button>
                </div>
              </WindowContent>
            </Window>
          </>
        )}
      </Wrapper>
    </ThemeProvider>
  );
}

export default App;
